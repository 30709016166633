import React, { useState, useEffect } from 'react'
import Layout from "../share/layout";
import '../../styles/template4.css'
import '../../styles/template4responsive.css'
import Image from 'gatsby-image';
import { Carousel } from 'react-responsive-carousel';
import Modal from 'react-bootstrap/Modal';
import BackgroundImage from 'gatsby-background-image';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import ShareButton from '../share/ShareButton';
import { useLocation } from "@reach/router"

function createMarkup(Markup) { return { __html: `${Markup}` }; };

const CarouselModal = ({ SliderImages, CarruselImageButtons = null }) => {
	return (
		<div>
			{SliderImages.length > 1 ?
				<>
					<button className="Button-Carousel-Next" aria-label="Carousel prev Button" onClick={() => { if (document.querySelector('.control-prev')) { document.querySelector('.control-prev').click() } }}>
						{CarruselImageButtons[0] ? <div className="button-carousel"><Image fluid={CarruselImageButtons[0].fluid} /></div> : <i className="fa fa-arrow-left" />}
					</button>
					<Carousel autoPlay infiniteLoop interval={10000} transitionTime={800} stopOnHover={false} showThumbs={false}>
						{SliderImages.map((SliderImage, i) => (
							<Image key={i} fluid={SliderImage.fluid} />
						))}
					</Carousel>
					<button className="Button-Carousel-Prev" aria-label="Carousel next Button" onClick={() => { if (document.querySelector('.control-next')) { document.querySelector('.control-next').click() } }}>
						{CarruselImageButtons[1] ? <div className="button-carousel"><Image fluid={CarruselImageButtons[1].fluid} /></div> : <i className="fa fa-arrow-right" />}
					</button>
				</> :
				<Carousel interval={10000000} showThumbs={false}>
					{SliderImages.map((SliderImage, i) => (
						<Image key={i} fluid={SliderImage.fluid} />
					))}
				</Carousel>
			}
		</div>
	)
}


const Template4 = ({ pageContext }) => {
	let location = useLocation();
	const QueryResultData = pageContext.datacarrusel;

	const { titulo,
		BackgroundsImages, SectionAImages, SectionAText, modalDesingImages, modalVideos, Section2TitleVideoCategories, Section2VideosButtonsA, Section2VideosButtonsB, Section2VideosModalText
		, Section2Images1ModalText, Section2Images2ModalText, Section2TitleVideoCategoriesModal, Section2PhotosButtonsA, Section2PhotosButtonsB, Section2ChroniclesButtonsA,
		Section2ChroniclesButtonsB, Section2TitlePhotoCategories, Section2TitleChroniclesCategories, Section2TitlePhotoCategoriesModal, Section2TitleChroniclesCategoriesModal,
		Section2Photo1ModalText, Section2Chronicles1ModalText, Section2PhotoModalText, Section2ChroniclesModalText, Section2DownloadMaterialsModal, Section2PhotoSliderModal,
		Section3Icons, Section3Text1, Section3Text2, Section3Images1, Section3Images2, terceraSeccinEnlacesDeInteres, Section3Links, Section3Part1Title, Section3Part2Title,
		iconoParaCerrarModales, botonesDeCarruselDeImagenes, adornosDeImagen,
		metaTitle, metaKeywords, metaDescripcin, ShareImage, subtitulo } = QueryResultData

	const [VideosIframe, setVideosIframe] = useState(null);
	const [ImagesSlider1A, setImagesSlider1A] = useState([]);
	const [ImagesSlider1B, setImagesSlider1B] = useState([]);
	const [ImagesSlider1C, setImagesSlider1C] = useState([]);
	const [ImagesSlider2A, setImagesSlider2A] = useState([]);
	const [ImagesSlider2B, setImagesSlider2B] = useState([]);
	const [ImagesSlider2C, setImagesSlider2C] = useState([]);
	const [ImagesSlider3A, setImagesSlider3A] = useState([]);
	const [ImagesSlider3B, setImagesSlider3B] = useState([]);
	const [ImagesSlider3C, setImagesSlider3C] = useState([]);
	const [ImagesSlider4A, setImagesSlider4A] = useState([]);
	const [ImagesSlider4B, setImagesSlider4B] = useState([]);
	const [ImagesSlider4C, setImagesSlider4C] = useState([]);
	const [ImagesSlider5A, setImagesSlider5A] = useState([]);
	const [ImagesSlider5B, setImagesSlider5B] = useState([]);
	const [ImagesSlider5C, setImagesSlider5C] = useState([]);
	const [ImagesSlider6A, setImagesSlider6A] = useState([]);
	const [ImagesSlider6B, setImagesSlider6B] = useState([]);
	const [ImagesSlider6C, setImagesSlider6C] = useState([]);
	const [ImagesSlider7A, setImagesSlider7A] = useState([]);
	const [ImagesSlider7B, setImagesSlider7B] = useState([]);
	const [ImagesSlider7C, setImagesSlider7C] = useState([]);
	const [ImagesSlider8A, setImagesSlider8A] = useState([]);
	const [ImagesSlider8B, setImagesSlider8B] = useState([]);
	const [ImagesSlider8C, setImagesSlider8C] = useState([]);
	const [ImagesSlider9A, setImagesSlider9A] = useState([]);
	const [ImagesSlider9B, setImagesSlider9B] = useState([]);
	const [ImagesSlider9C, setImagesSlider9C] = useState([]);
	const [ImagesSlider10A, setImagesSlider10A] = useState([]);
	const [ImagesSlider10B, setImagesSlider10B] = useState([]);
	const [ImagesSlider10C, setImagesSlider10C] = useState([]);


	const [ShowModal, setShowModal] = useState({
		modalvideo1A: false,
		modalvideo1B: false,
		modalvideo1C: false,
		modalvideo2A: false,
		modalvideo2B: false,
		modalvideo2C: false,
		modalvideo3A: false,
		modalvideo3B: false,
		modalvideo3C: false,
		modalvideo4A: false,
		modalvideo4B: false,
		modalvideo4C: false,
		modalvideo5A: false,
		modalvideo5B: false,
		modalvideo5C: false,
		modalvideo6A: false,
		modalvideo6B: false,
		modalvideo6C: false,
		modalvideo7A: false,
		modalvideo7B: false,
		modalvideo7C: false,
		modalvideo8A: false,
		modalvideo8B: false,
		modalvideo8C: false,
		modalvideo9A: false,
		modalvideo9B: false,
		modalvideo9C: false,
		modalvideo10A: false,
		modalvideo10B: false,
		modalvideo10C: false,
		modalfotos1A: false,
		modalfotos1B: false,
		modalfotos1C: false,
		modalfotos2A: false,
		modalfotos2B: false,
		modalfotos2C: false,
		modalfotos3A: false,
		modalfotos3B: false,
		modalfotos3C: false,
		modalfotos4A: false,
		modalfotos4B: false,
		modalfotos4C: false,
		modalfotos5A: false,
		modalfotos5B: false,
		modalfotos5C: false,
		modalfotos6A: false,
		modalfotos6B: false,
		modalfotos6C: false,
		modalfotos7A: false,
		modalfotos7B: false,
		modalfotos7C: false,
		modalfotos8A: false,
		modalfotos8B: false,
		modalfotos8C: false,
		modalfotos9A: false,
		modalfotos9B: false,
		modalfotos9C: false,
		modalfotos10A: false,
		modalfotos10B: false,
		modalfotos10C: false,
		modalcronica1A: false,
		modalcronica1B: false,
		modalcronica1C: false,
		modalcronica2A: false,
		modalcronica2B: false,
		modalcronica2C: false,
		modalcronica3A: false,
		modalcronica3B: false,
		modalcronica3C: false,
		modalcronica4A: false,
		modalcronica4B: false,
		modalcronica4C: false,
		modalcronica5A: false,
		modalcronica5B: false,
		modalcronica5C: false,
		modalcronica6A: false,
		modalcronica6B: false,
		modalcronica6C: false,
		modalcronica7A: false,
		modalcronica7B: false,
		modalcronica7C: false,
		modalcronica8A: false,
		modalcronica8B: false,
		modalcronica8C: false,
		modalcronica9A: false,
		modalcronica9B: false,
		modalcronica9C: false,
		modalcronica10A: false,
		modalcronica10B: false,
		modalcronica10C: false

	})


	const CarouselCloseModal = ({ i, type }) => {
		const ConditionalClose = () => {
			if (type === 'video') {
				if (i === 0) { setShowModal({ ...ShowModal, modalvideo1A: false }) }
				else if (i === 1) { setShowModal({ ...ShowModal, modalvideo1B: false }) }
				else if (i === 2) { setShowModal({ ...ShowModal, modalvideo1C: false }) }
				else if (i === 3) { setShowModal({ ...ShowModal, modalvideo2A: false }) }
				else if (i === 4) { setShowModal({ ...ShowModal, modalvideo2B: false }) }
				else if (i === 5) { setShowModal({ ...ShowModal, modalvideo2C: false }) }
				else if (i === 6) { setShowModal({ ...ShowModal, modalvideo3A: false }) }
				else if (i === 7) { setShowModal({ ...ShowModal, modalvideo3B: false }) }
				else if (i === 8) { setShowModal({ ...ShowModal, modalvideo3C: false }) }
				else if (i === 9) { setShowModal({ ...ShowModal, modalvideo4A: false }) }
				else if (i === 10) { setShowModal({ ...ShowModal, modalvideo4B: false }) }
				else if (i === 11) { setShowModal({ ...ShowModal, modalvideo4C: false }) }
				else if (i === 12) { setShowModal({ ...ShowModal, modalvideo5A: false }) }
				else if (i === 13) { setShowModal({ ...ShowModal, modalvideo5B: false }) }
				else if (i === 14) { setShowModal({ ...ShowModal, modalvideo5C: false }) }
				else if (i === 15) { setShowModal({ ...ShowModal, modalvideo6A: false }) }
				else if (i === 16) { setShowModal({ ...ShowModal, modalvideo6B: false }) }
				else if (i === 17) { setShowModal({ ...ShowModal, modalvideo6C: false }) }
				else if (i === 18) { setShowModal({ ...ShowModal, modalvideo7A: false }) }
				else if (i === 19) { setShowModal({ ...ShowModal, modalvideo7B: false }) }
				else if (i === 20) { setShowModal({ ...ShowModal, modalvideo7C: false }) }
				else if (i === 21) { setShowModal({ ...ShowModal, modalvideo8A: false }) }
				else if (i === 22) { setShowModal({ ...ShowModal, modalvideo8B: false }) }
				else if (i === 23) { setShowModal({ ...ShowModal, modalvideo8C: false }) }
				else if (i === 24) { setShowModal({ ...ShowModal, modalvideo9A: false }) }
				else if (i === 25) { setShowModal({ ...ShowModal, modalvideo9B: false }) }
				else if (i === 26) { setShowModal({ ...ShowModal, modalvideo9C: false }) }
				else if (i === 27) { setShowModal({ ...ShowModal, modalvideo10A: false }) }
				else if (i === 28) { setShowModal({ ...ShowModal, modalvideo10B: false }) }
				else if (i === 29) { setShowModal({ ...ShowModal, modalvideo10C: false }) }
			} else if (type === 'foto') {
				if (i === 0) { setShowModal({ ...ShowModal, modalfotos1A: false }) }
				else if (i === 1) { setShowModal({ ...ShowModal, modalfotos1B: false }) }
				else if (i === 2) { setShowModal({ ...ShowModal, modalfotos1C: false }) }
				else if (i === 3) { setShowModal({ ...ShowModal, modalfotos2A: false }) }
				else if (i === 4) { setShowModal({ ...ShowModal, modalfotos2B: false }) }
				else if (i === 5) { setShowModal({ ...ShowModal, modalfotos2C: false }) }
				else if (i === 6) { setShowModal({ ...ShowModal, modalfotos3A: false }) }
				else if (i === 7) { setShowModal({ ...ShowModal, modalfotos3B: false }) }
				else if (i === 8) { setShowModal({ ...ShowModal, modalfotos3C: false }) }
				else if (i === 9) { setShowModal({ ...ShowModal, modalfotos4A: false }) }
				else if (i === 10) { setShowModal({ ...ShowModal, modalfotos4B: false }) }
				else if (i === 11) { setShowModal({ ...ShowModal, modalfotos4C: false }) }
				else if (i === 12) { setShowModal({ ...ShowModal, modalfotos5A: false }) }
				else if (i === 13) { setShowModal({ ...ShowModal, modalfotos5B: false }) }
				else if (i === 14) { setShowModal({ ...ShowModal, modalfotos5C: false }) }
				else if (i === 15) { setShowModal({ ...ShowModal, modalfotos6A: false }) }
				else if (i === 16) { setShowModal({ ...ShowModal, modalfotos6B: false }) }
				else if (i === 17) { setShowModal({ ...ShowModal, modalfotos6C: false }) }
				else if (i === 18) { setShowModal({ ...ShowModal, modalfotos7A: false }) }
				else if (i === 19) { setShowModal({ ...ShowModal, modalfotos7B: false }) }
				else if (i === 20) { setShowModal({ ...ShowModal, modalfotos7C: false }) }
				else if (i === 21) { setShowModal({ ...ShowModal, modalfotos8A: false }) }
				else if (i === 22) { setShowModal({ ...ShowModal, modalfotos8B: false }) }
				else if (i === 23) { setShowModal({ ...ShowModal, modalfotos8C: false }) }
				else if (i === 24) { setShowModal({ ...ShowModal, modalfotos9A: false }) }
				else if (i === 25) { setShowModal({ ...ShowModal, modalfotos9B: false }) }
				else if (i === 26) { setShowModal({ ...ShowModal, modalfotos9C: false }) }
				else if (i === 27) { setShowModal({ ...ShowModal, modalfotos10A: false }) }
				else if (i === 28) { setShowModal({ ...ShowModal, modalfotos10B: false }) }
				else if (i === 29) { setShowModal({ ...ShowModal, modalfotos10C: false }) }
			} else if (type === 'cronica') {
				if (i === 0) { setShowModal({ ...ShowModal, modalcronica1A: false }) }
				else if (i === 1) { setShowModal({ ...ShowModal, modalcronica1B: false }) }
				else if (i === 2) { setShowModal({ ...ShowModal, modalcronica1C: false }) }
				else if (i === 3) { setShowModal({ ...ShowModal, modalcronica2A: false }) }
				else if (i === 4) { setShowModal({ ...ShowModal, modalcronica2B: false }) }
				else if (i === 5) { setShowModal({ ...ShowModal, modalcronica2C: false }) }
				else if (i === 6) { setShowModal({ ...ShowModal, modalcronica3A: false }) }
				else if (i === 7) { setShowModal({ ...ShowModal, modalcronica3B: false }) }
				else if (i === 8) { setShowModal({ ...ShowModal, modalcronica3C: false }) }
				else if (i === 9) { setShowModal({ ...ShowModal, modalcronica4A: false }) }
				else if (i === 10) { setShowModal({ ...ShowModal, modalcronica4B: false }) }
				else if (i === 11) { setShowModal({ ...ShowModal, modalcronica4C: false }) }
				else if (i === 12) { setShowModal({ ...ShowModal, modalcronica5A: false }) }
				else if (i === 13) { setShowModal({ ...ShowModal, modalcronica5B: false }) }
				else if (i === 14) { setShowModal({ ...ShowModal, modalcronica5C: false }) }
				else if (i === 15) { setShowModal({ ...ShowModal, modalcronica6A: false }) }
				else if (i === 16) { setShowModal({ ...ShowModal, modalcronica6B: false }) }
				else if (i === 17) { setShowModal({ ...ShowModal, modalcronica6C: false }) }
				else if (i === 18) { setShowModal({ ...ShowModal, modalcronica7A: false }) }
				else if (i === 19) { setShowModal({ ...ShowModal, modalcronica7B: false }) }
				else if (i === 20) { setShowModal({ ...ShowModal, modalcronica7C: false }) }
				else if (i === 21) { setShowModal({ ...ShowModal, modalcronica8A: false }) }
				else if (i === 22) { setShowModal({ ...ShowModal, modalcronica8B: false }) }
				else if (i === 23) { setShowModal({ ...ShowModal, modalcronica8C: false }) }
				else if (i === 24) { setShowModal({ ...ShowModal, modalcronica9A: false }) }
				else if (i === 25) { setShowModal({ ...ShowModal, modalcronica9B: false }) }
				else if (i === 26) { setShowModal({ ...ShowModal, modalcronica9C: false }) }
				else if (i === 27) { setShowModal({ ...ShowModal, modalcronica10A: false }) }
				else if (i === 28) { setShowModal({ ...ShowModal, modalcronica10B: false }) }
				else if (i === 29) { setShowModal({ ...ShowModal, modalcronica10C: false }) }
			}
		}

		return (
			<button className="close-modal-button" onClick={ConditionalClose}>
				<Image fluid={iconoParaCerrarModales.fluid} />
			</button>
		)
	}

	useEffect(() => {
		let UploadVideos = [];
		for (let i = 0; i < modalVideos.length; i++) {
			UploadVideos.push(modalVideos[i].trim().split(/\s*,\s*/));
		}
		setVideosIframe(UploadVideos);

		let ImagesSlider1ATemp = [];
		let ImagesSlider1BTemp = [];
		let ImagesSlider1CTemp = [];
		let ImagesSlider2ATemp = [];
		let ImagesSlider2BTemp = [];
		let ImagesSlider2CTemp = [];
		let ImagesSlider3ATemp = [];
		let ImagesSlider3BTemp = [];
		let ImagesSlider3CTemp = [];
		let ImagesSlider4ATemp = [];
		let ImagesSlider4BTemp = [];
		let ImagesSlider4CTemp = [];
		let ImagesSlider5ATemp = [];
		let ImagesSlider5BTemp = [];
		let ImagesSlider5CTemp = [];
		let ImagesSlider6ATemp = [];
		let ImagesSlider6BTemp = [];
		let ImagesSlider6CTemp = [];
		let ImagesSlider7ATemp = [];
		let ImagesSlider7BTemp = [];
		let ImagesSlider7CTemp = [];
		let ImagesSlider8ATemp = [];
		let ImagesSlider8BTemp = [];
		let ImagesSlider8CTemp = [];
		let ImagesSlider9ATemp = [];
		let ImagesSlider9BTemp = [];
		let ImagesSlider9CTemp = [];
		let ImagesSlider10ATemp = [];
		let ImagesSlider10BTemp = [];
		let ImagesSlider10CTemp = [];

		if (Section2PhotoSliderModal) {
			for (let i = 0; i < Section2PhotoSliderModal.length; i++) {
				if (Section2PhotoSliderModal[i].title.includes('#1')) {
					ImagesSlider1ATemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#2')) {
					ImagesSlider1BTemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#3')) {
					ImagesSlider1CTemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#4')) {
					ImagesSlider2ATemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#5')) {
					ImagesSlider2BTemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#6')) {
					ImagesSlider2CTemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#7')) {
					ImagesSlider3ATemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#8')) {
					ImagesSlider3BTemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#9')) {
					ImagesSlider3CTemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#10')) {
					ImagesSlider4ATemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#11')) {
					ImagesSlider4BTemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#12')) {
					ImagesSlider4CTemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#13')) {
					ImagesSlider5ATemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#14')) {
					ImagesSlider5BTemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#15')) {
					ImagesSlider5CTemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#16')) {
					ImagesSlider6ATemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#17')) {
					ImagesSlider6BTemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#18')) {
					ImagesSlider6CTemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#19')) {
					ImagesSlider7ATemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#20')) {
					ImagesSlider7BTemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#21')) {
					ImagesSlider7CTemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#22')) {
					ImagesSlider8ATemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#23')) {
					ImagesSlider8BTemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#24')) {
					ImagesSlider8CTemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#25')) {
					ImagesSlider9ATemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#26')) {
					ImagesSlider9BTemp.push(Section2PhotoSliderModal[i]);
				} else if (Section2PhotoSliderModal[i].title.includes('#27')) {
					ImagesSlider9CTemp.push(Section2PhotoSliderModal[i]);
				}
			}
		}

		setImagesSlider1A(ImagesSlider1ATemp)
		setImagesSlider1B(ImagesSlider1BTemp)
		setImagesSlider1C(ImagesSlider1CTemp)
		setImagesSlider2A(ImagesSlider2ATemp)
		setImagesSlider2B(ImagesSlider2BTemp)
		setImagesSlider2C(ImagesSlider2CTemp)
		setImagesSlider3A(ImagesSlider3ATemp)
		setImagesSlider3B(ImagesSlider3BTemp)
		setImagesSlider3C(ImagesSlider3CTemp)
		setImagesSlider4A(ImagesSlider4ATemp)
		setImagesSlider4B(ImagesSlider4BTemp)
		setImagesSlider4C(ImagesSlider4CTemp)
		setImagesSlider5A(ImagesSlider5ATemp)
		setImagesSlider5B(ImagesSlider5BTemp)
		setImagesSlider5C(ImagesSlider5CTemp)
		setImagesSlider6A(ImagesSlider6ATemp)
		setImagesSlider6B(ImagesSlider6BTemp)
		setImagesSlider6C(ImagesSlider6CTemp)
		setImagesSlider7A(ImagesSlider7ATemp)
		setImagesSlider7B(ImagesSlider7BTemp)
		setImagesSlider7C(ImagesSlider7CTemp)
		setImagesSlider8A(ImagesSlider8ATemp)
		setImagesSlider8B(ImagesSlider8BTemp)
		setImagesSlider8C(ImagesSlider8CTemp)
		setImagesSlider9A(ImagesSlider9ATemp)
		setImagesSlider9B(ImagesSlider9BTemp)
		setImagesSlider9C(ImagesSlider9CTemp)
		setImagesSlider10A(ImagesSlider10ATemp)
		setImagesSlider10B(ImagesSlider10BTemp)
		setImagesSlider10C(ImagesSlider10CTemp)
	}, [modalVideos, Section2PhotoSliderModal]);

	return (
		<Layout
			descritpion={subtitulo}
			subtitile={subtitulo}
			imgurl={ShareImage.file.url}
			title={titulo}
			metaTitle={metaTitle}
			metaKeywords={metaKeywords}
			metaDescripcin={metaDescripcin}
		>
			<ShareButton url={location.pathname} />
			<h1 className="d-none">{titulo}</h1>
			<h2 className="d-none">{subtitulo}</h2>
			<div className="template4">
				{BackgroundsImages[0] &&
					<BackgroundImage className="backgroundImage" fluid={BackgroundsImages[0].fluid}>
						{adornosDeImagen &&
							<div id="ornament">
								<Image fluid={adornosDeImagen.fluid} alt={adornosDeImagen.description ? adornosDeImagen.description : adornosDeImagen.title} />
							</div>
						}
						<div className="container">
							{SectionAImages[0] &&
								<Image className="Imagen-1" fluid={SectionAImages[0].fluid} alt={SectionAImages[0].description ? SectionAImages[0].description : SectionAImages[0].title} />
							}
							{SectionAText &&
								<div className="rich-text">
									{documentToReactComponents(SectionAText.json)}
								</div>
							}
							{SectionAImages[1] &&
								<Image className="Imagen-2" fluid={SectionAImages[1].fluid} alt={SectionAImages[1].description ? SectionAImages[1].description : SectionAImages[1].title} />
							}
						</div>
					</BackgroundImage>
				}
				{BackgroundsImages[1] &&
					<BackgroundImage className="backgroundImage" fluid={BackgroundsImages[1].fluid}>
						<div className="container">
							{Section2TitleVideoCategories !== null &&
								<React.Fragment>
									{Section2TitleVideoCategories.map((image, i) => (
										<div key={i} className="img-container">
											<div className="title-img">
												<Image fluid={image.fluid} alt={image.description ? image.description : image.title} />
											</div>
											<div className="category-container">
												<div className="button-container">
													<button onClick={() => {
														if (i === 0) { setShowModal({ ...ShowModal, modalvideo1A: true }) }
														else if (i === 1) { setShowModal({ ...ShowModal, modalvideo2A: true }) }
														else if (i === 2) { setShowModal({ ...ShowModal, modalvideo3A: true }) }
														else if (i === 3) { setShowModal({ ...ShowModal, modalvideo4A: true }) }
														else if (i === 4) { setShowModal({ ...ShowModal, modalvideo5A: true }) }
														else if (i === 5) { setShowModal({ ...ShowModal, modalvideo6A: true }) }
														else if (i === 6) { setShowModal({ ...ShowModal, modalvideo7A: true }) }
													}}>
														{(Section2VideosButtonsA && Section2VideosButtonsA[i * 3]) &&
															<Image fluid={Section2VideosButtonsA[i * 3].fluid} alt={Section2VideosButtonsA[i * 3].description ? Section2VideosButtonsA[i * 3].description : Section2VideosButtonsA[i * 3].title} />
														}
														{(Section2VideosButtonsB && Section2VideosButtonsB[i * 3]) &&
															<Image fluid={Section2VideosButtonsB[i * 3].fluid} alt={Section2VideosButtonsB[i * 3].description ? Section2VideosButtonsB[i * 3].description : Section2VideosButtonsB[i * 3].title} />
														}
													</button>
												</div>

												<div className="button-container">
													<button onClick={() => {
														if (i === 0) { setShowModal({ ...ShowModal, modalvideo1B: true }) }
														else if (i === 1) { setShowModal({ ...ShowModal, modalvideo2B: true }) }
														else if (i === 2) { setShowModal({ ...ShowModal, modalvideo3B: true }) }
														else if (i === 3) { setShowModal({ ...ShowModal, modalvideo4B: true }) }
														else if (i === 4) { setShowModal({ ...ShowModal, modalvideo5B: true }) }
														else if (i === 5) { setShowModal({ ...ShowModal, modalvideo6B: true }) }
														else if (i === 6) { setShowModal({ ...ShowModal, modalvideo7B: true }) }
													}}>
														{(Section2VideosButtonsA && Section2VideosButtonsA[i * 3 + 1]) &&
															<Image fluid={Section2VideosButtonsA[i * 3 + 1].fluid} alt={Section2VideosButtonsA[i * 3 + 1].description ? Section2VideosButtonsA[i * 3 + 1].description : Section2VideosButtonsA[i * 3 + 1].title} />
														}
														{(Section2VideosButtonsB && Section2VideosButtonsB[i * 3 + 1]) &&
															<Image fluid={Section2VideosButtonsB[i * 3 + 1].fluid} alt={Section2VideosButtonsB[i * 3 + 1].description ? Section2VideosButtonsB[i * 3 + 1].description : Section2VideosButtonsB[i * 3 + 1].title} />
														}
													</button>
												</div>

												<div className="button-container">
													<button onClick={() => {
														if (i === 0) { setShowModal({ ...ShowModal, modalvideo1C: true }) }
														else if (i === 1) { setShowModal({ ...ShowModal, modalvideo2C: true }) }
														else if (i === 2) { setShowModal({ ...ShowModal, modalvideo3C: true }) }
														else if (i === 3) { setShowModal({ ...ShowModal, modalvideo4C: true }) }
														else if (i === 4) { setShowModal({ ...ShowModal, modalvideo5C: true }) }
														else if (i === 5) { setShowModal({ ...ShowModal, modalvideo6C: true }) }
														else if (i === 6) { setShowModal({ ...ShowModal, modalvideo7C: true }) }
													}}>
														{(Section2VideosButtonsA && Section2VideosButtonsA[i * 3 + 2]) &&
															<Image fluid={Section2VideosButtonsA[i * 3 + 2].fluid} alt={Section2VideosButtonsA[i * 3 + 2].description ? Section2VideosButtonsA[i * 3 + 2].description : Section2VideosButtonsA[i * 3 + 2].title} />
														}
														{(Section2VideosButtonsB && Section2VideosButtonsB[i * 3 + 2]) &&
															<Image fluid={Section2VideosButtonsB[i * 3 + 2].fluid} alt={Section2VideosButtonsB[i * 3 + 2].description ? Section2VideosButtonsB[i * 3 + 2].description : Section2VideosButtonsB[i * 3 + 2].title} />
														}
													</button>
												</div>
											</div>
										</div>
									))}
								</React.Fragment>
							}


							{Section2TitlePhotoCategories !== null &&
								<React.Fragment>
									{Section2TitlePhotoCategories.map((image, i) => (
										<div key={i} className="img-container">
											<div className="title-img">
												<Image fluid={image.fluid} alt={image.description ? image.description : image.title} />
											</div>
											<div className="category-container">
												<div className="button-container">
													<button onClick={() => {
														if (i === 0) { setShowModal({ ...ShowModal, modalfotos1A: true }) }
														else if (i === 1) { setShowModal({ ...ShowModal, modalfotos2A: true }) }
														else if (i === 2) { setShowModal({ ...ShowModal, modalfotos3A: true }) }
														else if (i === 3) { setShowModal({ ...ShowModal, modalfotos4A: true }) }
														else if (i === 4) { setShowModal({ ...ShowModal, modalfotos5A: true }) }
														else if (i === 5) { setShowModal({ ...ShowModal, modalfotos6A: true }) }
														else if (i === 6) { setShowModal({ ...ShowModal, modalfotos7A: true }) }
													}}>
														{(Section2PhotosButtonsA && Section2PhotosButtonsA[i * 3]) &&
															<Image fluid={Section2PhotosButtonsA[i * 3].fluid} alt={Section2PhotosButtonsA[i * 3].description ? Section2PhotosButtonsA[i * 3].description : Section2PhotosButtonsA[i * 3].title} />
														}
														{(Section2PhotosButtonsB && Section2PhotosButtonsB[i * 3]) &&
															<Image fluid={Section2PhotosButtonsB[i * 3].fluid} alt={Section2PhotosButtonsB[i * 3].description ? Section2PhotosButtonsB[i * 3].description : Section2PhotosButtonsB[i * 3].title} />
														}
													</button>
												</div>

												<div className="button-container">
													<button onClick={() => {
														if (i === 0) { setShowModal({ ...ShowModal, modalfotos1B: true }) }
														else if (i === 1) { setShowModal({ ...ShowModal, modalfotos2B: true }) }
														else if (i === 2) { setShowModal({ ...ShowModal, modalfotos3B: true }) }
														else if (i === 3) { setShowModal({ ...ShowModal, modalfotos4B: true }) }
														else if (i === 4) { setShowModal({ ...ShowModal, modalfotos5B: true }) }
														else if (i === 5) { setShowModal({ ...ShowModal, modalfotos6B: true }) }
														else if (i === 6) { setShowModal({ ...ShowModal, modalfotos7B: true }) }
													}}>
														{(Section2PhotosButtonsA && Section2PhotosButtonsA[i * 3 + 1]) &&
															<Image fluid={Section2PhotosButtonsA[i * 3 + 1].fluid} alt={Section2PhotosButtonsA[i * 3 + 1].description ? Section2PhotosButtonsA[i * 3 + 1].description : Section2PhotosButtonsA[i * 3 + 1].title} />
														}
														{(Section2PhotosButtonsB && Section2PhotosButtonsB[i * 3 + 1]) &&
															<Image fluid={Section2PhotosButtonsB[i * 3 + 1].fluid} alt={Section2PhotosButtonsB[i * 3 + 1].description ? Section2PhotosButtonsB[i * 3 + 1].description : Section2PhotosButtonsB[i * 3 + 1].title} />
														}
													</button>
												</div>

												<div className="button-container">
													<button onClick={() => {
														if (i === 0) { setShowModal({ ...ShowModal, modalfotos1C: true }) }
														else if (i === 1) { setShowModal({ ...ShowModal, modalfotos2C: true }) }
														else if (i === 2) { setShowModal({ ...ShowModal, modalfotos3C: true }) }
														else if (i === 3) { setShowModal({ ...ShowModal, modalfotos4C: true }) }
														else if (i === 4) { setShowModal({ ...ShowModal, modalfotos5C: true }) }
														else if (i === 5) { setShowModal({ ...ShowModal, modalfotos6C: true }) }
														else if (i === 6) { setShowModal({ ...ShowModal, modalfotos7C: true }) }
													}}>
														{(Section2PhotosButtonsA && Section2PhotosButtonsA[i * 3 + 2]) &&
															<Image fluid={Section2PhotosButtonsA[i * 3 + 2].fluid} alt={Section2PhotosButtonsA[i * 3 + 2].description ? Section2PhotosButtonsA[i * 3 + 2].description : Section2PhotosButtonsA[i * 3 + 2].title} />
														}
														{(Section2PhotosButtonsB && Section2PhotosButtonsB[i * 3 + 2]) &&
															<Image fluid={Section2PhotosButtonsB[i * 3 + 2].fluid} alt={Section2PhotosButtonsB[i * 3 + 2].description ? Section2PhotosButtonsB[i * 3 + 2].description : Section2PhotosButtonsB[i * 3 + 2].title} />
														}
													</button>
												</div>
											</div>
										</div>
									))}
								</React.Fragment>
							}

							{Section2TitleChroniclesCategories !== null &&
								<React.Fragment>
									{Section2TitleChroniclesCategories.map((image, i) => (
										<div key={i} className="img-container">
											<div className="title-img">
												<Image fluid={image.fluid} alt={image.description ? image.description : image.title} />
											</div>
											<div className="category-container">
												<div className="button-container">
													<button onClick={() => {
														if (i === 0) { setShowModal({ ...ShowModal, modalcronica1A: true }) }
														else if (i === 1) { setShowModal({ ...ShowModal, modalcronica2A: true }) }
														else if (i === 2) { setShowModal({ ...ShowModal, modalcronica3A: true }) }
														else if (i === 3) { setShowModal({ ...ShowModal, modalcronica4A: true }) }
														else if (i === 4) { setShowModal({ ...ShowModal, modalcronica5A: true }) }
														else if (i === 5) { setShowModal({ ...ShowModal, modalcronica6A: true }) }
														else if (i === 6) { setShowModal({ ...ShowModal, modalcronica7A: true }) }
													}}>
														{(Section2ChroniclesButtonsA && Section2ChroniclesButtonsA[i * 3]) &&
															<Image fluid={Section2ChroniclesButtonsA[i * 3].fluid} alt={Section2ChroniclesButtonsA[i].description ? Section2ChroniclesButtonsA[i].description : Section2PhotosButtonsA[i].title} />
														}
														{(Section2ChroniclesButtonsB && Section2ChroniclesButtonsB[i * 3]) &&
															<Image fluid={Section2ChroniclesButtonsB[i * 3].fluid} alt={Section2ChroniclesButtonsB[i].description ? Section2ChroniclesButtonsB[i].description : Section2PhotosButtonsB[i].title} />
														}
													</button>
												</div>

												<div className="button-container">
													<button onClick={() => {
														if (i === 0) { setShowModal({ ...ShowModal, modalcronica1B: true }) }
														else if (i === 1) { setShowModal({ ...ShowModal, modalcronica2B: true }) }
														else if (i === 2) { setShowModal({ ...ShowModal, modalcronica3B: true }) }
														else if (i === 3) { setShowModal({ ...ShowModal, modalcronica4B: true }) }
														else if (i === 4) { setShowModal({ ...ShowModal, modalcronica5B: true }) }
														else if (i === 5) { setShowModal({ ...ShowModal, modalcronica6B: true }) }
														else if (i === 6) { setShowModal({ ...ShowModal, modalcronica7B: true }) }
													}}>
														{(Section2ChroniclesButtonsA && Section2ChroniclesButtonsA[i * 3 + 1]) &&
															<Image fluid={Section2ChroniclesButtonsA[i * 3 + 1].fluid} alt={Section2ChroniclesButtonsA[i * 3 + 1].description ? Section2ChroniclesButtonsA[i * 3 + 1].description : Section2PhotosButtonsA[i * 3 + 1].title} />
														}
														{(Section2ChroniclesButtonsB[i * 3 + 1] && Section2ChroniclesButtonsB) &&
															<Image fluid={Section2ChroniclesButtonsB[i * 3 + 1].fluid} alt={Section2ChroniclesButtonsB[i * 3 + 1].description ? Section2ChroniclesButtonsB[i * 3 + 1].description : Section2PhotosButtonsB[i * 3 + 1].title} />
														}
													</button>
												</div>

												<div className="button-container">
													<button onClick={() => {
														if (i === 0) { setShowModal({ ...ShowModal, modalcronica1C: true }) }
														else if (i === 1) { setShowModal({ ...ShowModal, modalcronica2C: true }) }
														else if (i === 2) { setShowModal({ ...ShowModal, modalcronica3C: true }) }
														else if (i === 3) { setShowModal({ ...ShowModal, modalcronica4C: true }) }
														else if (i === 4) { setShowModal({ ...ShowModal, modalcronica5C: true }) }
														else if (i === 5) { setShowModal({ ...ShowModal, modalcronica6C: true }) }
														else if (i === 6) { setShowModal({ ...ShowModal, modalcronica7C: true }) }
													}}>
														{(Section2ChroniclesButtonsA && Section2ChroniclesButtonsA[i * 3 + 2]) &&
															<Image fluid={Section2ChroniclesButtonsA[i * 3 + 2].fluid} alt={Section2ChroniclesButtonsA[i * 3 + 2].description ? Section2ChroniclesButtonsA[i * 3 + 2].description : Section2PhotosButtonsA[i * 3 + 2].title} />
														}
														{(Section2ChroniclesButtonsB && Section2ChroniclesButtonsB[i * 3 + 2]) &&
															<Image fluid={Section2ChroniclesButtonsB[i * 3 + 2].fluid} alt={Section2ChroniclesButtonsB[i * 3 + 2].description ? Section2ChroniclesButtonsB[i * 3 + 2].description : Section2PhotosButtonsB[i * 3 + 2].title} />
														}
													</button>
												</div>
											</div>
										</div>
									))}
								</React.Fragment>
							}

						</div>
						<div className="container">
							<div className="section-1 section">
								<div className="icon-container">
									{(Section3Icons && Section3Icons[0]) && <Image fluid={Section3Icons[0].fluid} alt={Section3Icons[0].description ? Section3Icons[0].description : Section3Icons[0].title} />}
								</div>
								<div className="subcontainer">
									{Section3Images1 && Section3Images1.map((Section3Image, i) => (
										<div className="comment-container" key={i}>
											<div className="photo-container">
												<Image fluid={Section3Image.fluid} alt={Section3Image.description ? Section3Image.description : Section3Image.title} />
											</div>
											{Section3Text1.Plantilla4.Textos[i] &&
												<React.Fragment>
													<div className="text-container">
														{(Section3Part1Title && Section3Part1Title[i]) && <p className="title"><b>{Section3Part1Title[i]}</b></p>}
														<div dangerouslySetInnerHTML={createMarkup(Section3Text1.Plantilla4.Textos[i].texto)} />
													</div>
												</React.Fragment>
											}
										</div>
									))}
								</div>
							</div>
							<div className="section-2 section">
								<div className="icon-container">
									{(Section3Icons && Section3Icons[1]) && <Image fluid={Section3Icons[1].fluid} alt={Section3Icons[1].description ? Section3Icons[1].description : Section3Icons[1].title} />}
								</div>
								<div className="subcontainer">
									{Section3Images2 && Section3Images2.map((Section3Image, i) => (
										<div className="comment-container" key={i}>
											<div className="photo-container">
												<Image fluid={Section3Image.fluid} alt={Section3Image.description ? Section3Image.description : Section3Image.title} />
											</div>
											{(Section3Text2 && Section3Text2.Plantilla4 && Section3Text2.Plantilla4.Textos && Section3Text2.Plantilla4.Textos[i]) &&
												<React.Fragment>
													<div className="text-container">
														{(Section3Part2Title && Section3Part2Title[i]) && <p className="title"><b>{Section3Part2Title[i]}</b></p>}
														<div dangerouslySetInnerHTML={createMarkup(Section3Text2.Plantilla4.Textos[i].texto)} />
													</div>
												</React.Fragment>
											}
										</div>
									))}
								</div>
							</div>
							<div className="section-3 section">
								<div className="icon-container">
									{(Section3Icons && Section3Icons[2]) && <Image fluid={Section3Icons[2].fluid} alt={Section3Icons[2].description ? Section3Icons[2].description : Section3Icons[2].title} />}
								</div>
								<div className="subcontainer">
									{terceraSeccinEnlacesDeInteres && terceraSeccinEnlacesDeInteres.map((Imagenes, i) => (
										<div className="photo-container" key={i}>
											{Section3Links[i] ? <a href={Section3Links[i]} aria-label="redirect" rel="noopener noreferrer" target="_blank"><Image fluid={Imagenes.fluid} alt={Imagenes.description ? Imagenes.description : Imagenes.title} /></a> : <Image fluid={Imagenes.fluid} alt={Imagenes.description ? Imagenes.description : Imagenes.title} />}
										</div>
									))}
								</div>
							</div>
						</div>
					</BackgroundImage>
				}
			</div>


			{Section2TitleVideoCategories !== null && VideosIframe !== null &&
				<React.Fragment >
					{Section2VideosButtonsA.map((modal, i) => (
						<Modal key={i} show={
							i === 0 ? ShowModal.modalvideo1A :
								i === 1 ? ShowModal.modalvideo1B :
									i === 2 ? ShowModal.modalvideo1C :
										i === 3 ? ShowModal.modalvideo2A :
											i === 4 ? ShowModal.modalvideo2B :
												i === 5 ? ShowModal.modalvideo2C :
													i === 6 ? ShowModal.modalvideo3A :
														i === 7 ? ShowModal.modalvideo3B :
															i === 8 ? ShowModal.modalvideo3C :
																i === 9 ? ShowModal.modalvideo4A :
																	i === 10 ? ShowModal.modalvideo4B :
																		i === 11 ? ShowModal.modalvideo4C :
																			i === 12 ? ShowModal.modalvideo5A :
																				i === 13 ? ShowModal.modalvideo5B :
																					i === 14 ? ShowModal.modalvideo5C :
																						i === 15 ? ShowModal.modalvideo6A :
																							i === 16 ? ShowModal.modalvideo6B :
																								i === 17 ? ShowModal.modalvideo6C :
																									i === 18 ? ShowModal.modalvideo7A :
																										i === 19 ? ShowModal.modalvideo7B :
																											i === 20 ? ShowModal.modalvideo7C :
																												i === 21 ? ShowModal.modalvideo8A :
																													i === 22 ? ShowModal.modalvideo8B :
																														i === 23 ? ShowModal.modalvideo8C :
																															i === 24 ? ShowModal.modalvideo9A :
																																i === 25 ? ShowModal.modalvideo9B :
																																	i === 26 ? ShowModal.modalvideo9C :
																																		i === 27 ? ShowModal.modalvideo10A :
																																			i === 28 ? ShowModal.modalvideo10B :
																																				i === 29 && ShowModal.modalvideo10C
						}
							onHide={() => {
								if (i === 0) { setShowModal({ ...ShowModal, modalvideo1A: false }) }
								else if (i === 1) { setShowModal({ ...ShowModal, modalvideo1B: false }) }
								else if (i === 2) { setShowModal({ ...ShowModal, modalvideo1C: false }) }
								else if (i === 3) { setShowModal({ ...ShowModal, modalvideo2A: false }) }
								else if (i === 4) { setShowModal({ ...ShowModal, modalvideo2B: false }) }
								else if (i === 5) { setShowModal({ ...ShowModal, modalvideo2C: false }) }
								else if (i === 6) { setShowModal({ ...ShowModal, modalvideo3A: false }) }
								else if (i === 7) { setShowModal({ ...ShowModal, modalvideo3B: false }) }
								else if (i === 8) { setShowModal({ ...ShowModal, modalvideo3C: false }) }
								else if (i === 9) { setShowModal({ ...ShowModal, modalvideo4A: false }) }
								else if (i === 10) { setShowModal({ ...ShowModal, modalvideo4B: false }) }
								else if (i === 11) { setShowModal({ ...ShowModal, modalvideo4C: false }) }
								else if (i === 12) { setShowModal({ ...ShowModal, modalvideo5A: false }) }
								else if (i === 13) { setShowModal({ ...ShowModal, modalvideo5B: false }) }
								else if (i === 14) { setShowModal({ ...ShowModal, modalvideo5C: false }) }
								else if (i === 15) { setShowModal({ ...ShowModal, modalvideo6A: false }) }
								else if (i === 16) { setShowModal({ ...ShowModal, modalvideo6B: false }) }
								else if (i === 17) { setShowModal({ ...ShowModal, modalvideo6C: false }) }
								else if (i === 18) { setShowModal({ ...ShowModal, modalvideo7A: false }) }
								else if (i === 19) { setShowModal({ ...ShowModal, modalvideo7B: false }) }
								else if (i === 20) { setShowModal({ ...ShowModal, modalvideo7C: false }) }
								else if (i === 21) { setShowModal({ ...ShowModal, modalvideo8A: false }) }
								else if (i === 22) { setShowModal({ ...ShowModal, modalvideo8B: false }) }
								else if (i === 23) { setShowModal({ ...ShowModal, modalvideo8C: false }) }
								else if (i === 24) { setShowModal({ ...ShowModal, modalvideo9A: false }) }
								else if (i === 25) { setShowModal({ ...ShowModal, modalvideo9B: false }) }
								else if (i === 26) { setShowModal({ ...ShowModal, modalvideo9C: false }) }
								else if (i === 27) { setShowModal({ ...ShowModal, modalvideo10A: false }) }
								else if (i === 28) { setShowModal({ ...ShowModal, modalvideo10B: false }) }
								else if (i === 29) { setShowModal({ ...ShowModal, modalvideo10C: false }) }
							}}
							className="Modal-Template-4">
							<Modal.Body>
								{
									<BackgroundImage fluid={BackgroundsImages[2].fluid}>
										{(Section2TitleVideoCategoriesModal && Section2TitleVideoCategoriesModal[i]) &&
											<div className="title">
												<Image fluid={Section2TitleVideoCategoriesModal[i].fluid} alt={Section2TitleVideoCategoriesModal[i].description ? Section2TitleVideoCategoriesModal[i].description : Section2TitleVideoCategoriesModal[i].title} />
												<CarouselCloseModal i={i} type={'video'} />
											</div>
										}
										{VideosIframe[i] &&
											<div className="iframe-modal">
												<iframe title={titulo} width="560" height="315" src={VideosIframe[i].length > 1 ? `${VideosIframe[i][0]}?title=0&portrait=0&byline=0` : `${VideosIframe[i]}?title=0&portrait=0&byline=0`}></iframe>
											</div>
										}
										<div className="Modal-subcontainer">
											<div>
												{(i === 0 || i === 3 || i === 6 || i === 9 || i === 12 || i === 15 || i === 18 || i === 21 || i === 24 || i === 27) && <Image fluid={modalDesingImages[0].fluid} alt={modalDesingImages[0].description ? modalDesingImages[0].description : modalDesingImages[0].title} />}
												{(i === 1 || i === 4 || i === 7 || i === 10 || i === 13 || i === 16 || i === 19 || i === 22 || i === 25 || i === 28) && <Image fluid={modalDesingImages[1].fluid} alt={modalDesingImages[1].description ? modalDesingImages[1].description : modalDesingImages[1].title} />}
												{(i === 2 || i === 5 || i === 8 || i === 11 || i === 14 || i === 17 || i === 20 || i === 23 || i === 26 || i === 29) && <Image fluid={modalDesingImages[2].fluid} alt={modalDesingImages[2].description ? modalDesingImages[2].description : modalDesingImages[2].title} />}
											</div>
											<div className="Modal-description">
												<div className="Modal-subdescription">
													{Section2Images1ModalText[i] &&
														<Image fluid={Section2Images1ModalText[i].fluid} alt={Section2Images1ModalText[i].description ? Section2Images1ModalText[i].description : Section2Images1ModalText[i].title} />
													}
													{Section2Images2ModalText[i] &&
														<Image fluid={Section2Images2ModalText[i].fluid} alt={Section2Images2ModalText[i].description ? Section2Images2ModalText[i].description : Section2Images2ModalText[i].title} />
													}
												</div>
												<div className="Modal-subdescription">
													{Section2VideosModalText.Plantilla4 &&
														<React.Fragment>
															{Section2VideosModalText.Plantilla4.Textos && Section2VideosModalText.Plantilla4.Textos[i] &&
																<React.Fragment>
																	{<div dangerouslySetInnerHTML={createMarkup(Section2VideosModalText.Plantilla4.Textos[i].texto)} />}
																</React.Fragment>
															}
														</React.Fragment>
													}
												</div>
											</div>
											<div className="iframe-modal-container">
												<hr></hr>
												{VideosIframe[i] &&
													<React.Fragment>
														{VideosIframe[i].length > 1 &&
															<React.Fragment>
																<div>
																	{(modalDesingImages && modalDesingImages[4]) &&
																		<Image fluid={modalDesingImages[4].fluid} alt={modalDesingImages[4].description ? modalDesingImages[4].description : modalDesingImages[4].title} />
																	}
																	{VideosIframe[i].map((VideoIframe, x) => (
																		<React.Fragment key={x}>
																			{x > 0 &&
																				<div className="iframe-modal">
																					<iframe title={titulo} src={`${VideoIframe}?title=0&portrait=0&byline=0`} width="560" height="315" />
																				</div>
																			}
																		</React.Fragment>
																	))}
																</div>
															</React.Fragment>
														}
													</React.Fragment>
												}
											</div>
										</div>
									</BackgroundImage>
								}
							</Modal.Body>
						</Modal>
					))}
				</React.Fragment>
			}

			{Section2TitlePhotoCategoriesModal !== null &&
				<React.Fragment >
					{Section2PhotosButtonsA.map((modal, i) => (
						<Modal key={i} show={
							i === 0 ? ShowModal.modalfotos1A :
								i === 1 ? ShowModal.modalfotos1B :
									i === 2 ? ShowModal.modalfotos1C :
										i === 3 ? ShowModal.modalfotos2A :
											i === 4 ? ShowModal.modalfotos2B :
												i === 5 ? ShowModal.modalfotos2C :
													i === 6 ? ShowModal.modalfotos3A :
														i === 7 ? ShowModal.modalfotos3B :
															i === 8 ? ShowModal.modalfotos3C :
																i === 9 ? ShowModal.modalfotos4A :
																	i === 10 ? ShowModal.modalfotos4B :
																		i === 11 ? ShowModal.modalfotos4C :
																			i === 12 ? ShowModal.modalfotos5A :
																				i === 13 ? ShowModal.modalfotos5B :
																					i === 14 ? ShowModal.modalfotos5C :
																						i === 15 ? ShowModal.modalfotos6A :
																							i === 16 ? ShowModal.modalfotos6B :
																								i === 17 ? ShowModal.modalfotos6C :
																									i === 18 ? ShowModal.modalfotos7A :
																										i === 19 ? ShowModal.modalfotos7B :
																											i === 20 ? ShowModal.modalfotos7C :
																												i === 21 ? ShowModal.modalfotos8A :
																													i === 22 ? ShowModal.modalfotos8B :
																														i === 23 ? ShowModal.modalfotos8C :
																															i === 24 ? ShowModal.modalfotos9A :
																																i === 25 ? ShowModal.modalfotos9B :
																																	i === 26 ? ShowModal.modalfotos9C :
																																		i === 27 ? ShowModal.modalfotos10A :
																																			i === 28 ? ShowModal.modalfotos10B :
																																				i === 29 && ShowModal.modalfotos10C
						}
							onHide={() => {
								if (i === 0) { setShowModal({ ...ShowModal, modalfotos1A: false }) }
								else if (i === 1) { setShowModal({ ...ShowModal, modalfotos1B: false }) }
								else if (i === 2) { setShowModal({ ...ShowModal, modalfotos1C: false }) }
								else if (i === 3) { setShowModal({ ...ShowModal, modalfotos2A: false }) }
								else if (i === 4) { setShowModal({ ...ShowModal, modalfotos2B: false }) }
								else if (i === 5) { setShowModal({ ...ShowModal, modalfotos2C: false }) }
								else if (i === 6) { setShowModal({ ...ShowModal, modalfotos3A: false }) }
								else if (i === 7) { setShowModal({ ...ShowModal, modalfotos3B: false }) }
								else if (i === 8) { setShowModal({ ...ShowModal, modalfotos3C: false }) }
								else if (i === 9) { setShowModal({ ...ShowModal, modalfotos4A: false }) }
								else if (i === 10) { setShowModal({ ...ShowModal, modalfotos4B: false }) }
								else if (i === 11) { setShowModal({ ...ShowModal, modalfotos4C: false }) }
								else if (i === 12) { setShowModal({ ...ShowModal, modalfotos5A: false }) }
								else if (i === 13) { setShowModal({ ...ShowModal, modalfotos5B: false }) }
								else if (i === 14) { setShowModal({ ...ShowModal, modalfotos5C: false }) }
								else if (i === 15) { setShowModal({ ...ShowModal, modalfotos6A: false }) }
								else if (i === 16) { setShowModal({ ...ShowModal, modalfotos6B: false }) }
								else if (i === 17) { setShowModal({ ...ShowModal, modalfotos6C: false }) }
								else if (i === 18) { setShowModal({ ...ShowModal, modalfotos7A: false }) }
								else if (i === 19) { setShowModal({ ...ShowModal, modalfotos7B: false }) }
								else if (i === 20) { setShowModal({ ...ShowModal, modalfotos7C: false }) }
								else if (i === 21) { setShowModal({ ...ShowModal, modalfotos8A: false }) }
								else if (i === 22) { setShowModal({ ...ShowModal, modalfotos8B: false }) }
								else if (i === 23) { setShowModal({ ...ShowModal, modalfotos8C: false }) }
								else if (i === 24) { setShowModal({ ...ShowModal, modalfotos9A: false }) }
								else if (i === 25) { setShowModal({ ...ShowModal, modalfotos9B: false }) }
								else if (i === 26) { setShowModal({ ...ShowModal, modalfotos9C: false }) }
								else if (i === 27) { setShowModal({ ...ShowModal, modalfotos10A: false }) }
								else if (i === 28) { setShowModal({ ...ShowModal, modalfotos10B: false }) }
								else if (i === 29) { setShowModal({ ...ShowModal, modalfotos10C: false }) }
							}}
							className="Modal-Template-4 Modal-Template-4B">
							<Modal.Body>
								{
									<BackgroundImage fluid={BackgroundsImages[2].fluid}>
										{(Section2TitlePhotoCategoriesModal && Section2TitlePhotoCategoriesModal[i]) &&
											<div className="title">
												<Image fluid={Section2TitlePhotoCategoriesModal[i].fluid} />
												<CarouselCloseModal i={i} type={'foto'} />
											</div>
										}
										<div className="Modal-subcontainer py-0">
											<CarouselModal SliderImages={
												i === 0 ? ImagesSlider1A :
													i === 1 ? ImagesSlider1B :
														i === 2 ? ImagesSlider1C :
															i === 3 ? ImagesSlider2A :
																i === 4 ? ImagesSlider2B :
																	i === 5 ? ImagesSlider2C :
																		i === 6 ? ImagesSlider3A :
																			i === 7 ? ImagesSlider3B :
																				i === 8 ? ImagesSlider3C :
																					i === 9 ? ImagesSlider4A :
																						i === 10 ? ImagesSlider4B :
																							i === 11 ? ImagesSlider4C :
																								i === 12 ? ImagesSlider5A :
																									i === 13 ? ImagesSlider5B :
																										i === 14 ? ImagesSlider5C :
																											i === 15 ? ImagesSlider6A :
																												i === 16 ? ImagesSlider6B :
																													i === 17 ? ImagesSlider6C :
																														i === 18 ? ImagesSlider7A :
																															i === 19 ? ImagesSlider7B :
																																i === 20 ? ImagesSlider7C :
																																	i === 21 ? ImagesSlider8A :
																																		i === 22 ? ImagesSlider8B :
																																			i === 23 ? ImagesSlider8C :
																																				i === 24 ? ImagesSlider9A :
																																					i === 25 ? ImagesSlider9B :
																																						i === 26 ? ImagesSlider9C :
																																							i === 27 ? ImagesSlider10A :
																																								i === 28 ? ImagesSlider10B :
																																									i === 29 && ImagesSlider10C}
												CarruselImageButtons={botonesDeCarruselDeImagenes}
											/>
										</div>
										<div className="Modal-subcontainer">
											<div>
												{(i === 0 || i === 3 || i === 6 || i === 9 || i === 12 || i === 15 || i === 18 || i === 21 || i === 24 || i === 27) && <Image fluid={modalDesingImages[0].fluid} />}
												{(i === 1 || i === 4 || i === 7 || i === 10 || i === 13 || i === 16 || i === 19 || i === 22 || i === 25 || i === 28) && <Image fluid={modalDesingImages[1].fluid} />}
												{(i === 2 || i === 5 || i === 8 || i === 11 || i === 14 || i === 17 || i === 20 || i === 23 || i === 26 || i === 29) && <Image fluid={modalDesingImages[2].fluid} />}
											</div>
											<div className="Modal-description">
												<div className="Modal-subdescription">
													{Section2Photo1ModalText[i] &&
														<Image fluid={Section2Photo1ModalText[i].fluid} alt={Section2Photo1ModalText[i].description ? Section2Photo1ModalText[i].description : Section2Photo1ModalText[i].title} />
													}
												</div>
												<div className="Modal-subdescription">
													{Section2PhotoModalText.Plantilla4 &&
														<React.Fragment>
															{Section2PhotoModalText.Plantilla4.Textos && Section2PhotoModalText.Plantilla4.Textos[i] &&
																<React.Fragment>
																	{<div dangerouslySetInnerHTML={createMarkup(Section2PhotoModalText.Plantilla4.Textos[i].texto)} />}
																</React.Fragment>
															}
														</React.Fragment>
													}
												</div>
											</div>
										</div>
									</BackgroundImage>
								}
							</Modal.Body>
						</Modal>
					))}
				</React.Fragment>
			}


			{Section2TitleChroniclesCategoriesModal !== null &&
				<React.Fragment >
					{Section2ChroniclesButtonsA.map((modal, i) => (
						<Modal key={i} show={
							i === 0 ? ShowModal.modalcronica1A :
								i === 1 ? ShowModal.modalcronica1B :
									i === 2 ? ShowModal.modalcronica1C :
										i === 3 ? ShowModal.modalcronica2A :
											i === 4 ? ShowModal.modalcronica2B :
												i === 5 ? ShowModal.modalcronica2C :
													i === 6 ? ShowModal.modalcronica3A :
														i === 7 ? ShowModal.modalcronica3B :
															i === 8 ? ShowModal.modalcronica3C :
																i === 9 ? ShowModal.modalcronica4A :
																	i === 10 ? ShowModal.modalcronica4B :
																		i === 11 ? ShowModal.modalcronica4C :
																			i === 12 ? ShowModal.modalcronica5A :
																				i === 13 ? ShowModal.modalcronica5B :
																					i === 14 ? ShowModal.modalcronica5C :
																						i === 15 ? ShowModal.modalcronica6A :
																							i === 16 ? ShowModal.modalcronica6B :
																								i === 17 ? ShowModal.modalcronica6C :
																									i === 18 ? ShowModal.modalcronica7A :
																										i === 19 ? ShowModal.modalcronica7B :
																											i === 20 ? ShowModal.modalcronica7C :
																												i === 21 ? ShowModal.modalcronica8A :
																													i === 22 ? ShowModal.modalcronica8B :
																														i === 23 ? ShowModal.modalcronica8C :
																															i === 24 ? ShowModal.modalcronica9A :
																																i === 25 ? ShowModal.modalcronica9B :
																																	i === 26 ? ShowModal.modalcronica9C :
																																		i === 27 ? ShowModal.modalcronica10A :
																																			i === 28 ? ShowModal.modalcronica10B :
																																				i === 29 && ShowModal.modalcronica10C
						}
							onHide={() => {
								if (i === 0) { setShowModal({ ...ShowModal, modalcronica1A: false }) }
								else if (i === 1) { setShowModal({ ...ShowModal, modalcronica1B: false }) }
								else if (i === 2) { setShowModal({ ...ShowModal, modalcronica1C: false }) }
								else if (i === 3) { setShowModal({ ...ShowModal, modalcronica2A: false }) }
								else if (i === 4) { setShowModal({ ...ShowModal, modalcronica2B: false }) }
								else if (i === 5) { setShowModal({ ...ShowModal, modalcronica2C: false }) }
								else if (i === 6) { setShowModal({ ...ShowModal, modalcronica3A: false }) }
								else if (i === 7) { setShowModal({ ...ShowModal, modalcronica3B: false }) }
								else if (i === 8) { setShowModal({ ...ShowModal, modalcronica3C: false }) }
								else if (i === 9) { setShowModal({ ...ShowModal, modalcronica4A: false }) }
								else if (i === 10) { setShowModal({ ...ShowModal, modalcronica4B: false }) }
								else if (i === 11) { setShowModal({ ...ShowModal, modalcronica4C: false }) }
								else if (i === 12) { setShowModal({ ...ShowModal, modalcronica5A: false }) }
								else if (i === 13) { setShowModal({ ...ShowModal, modalcronica5B: false }) }
								else if (i === 14) { setShowModal({ ...ShowModal, modalcronica5C: false }) }
								else if (i === 15) { setShowModal({ ...ShowModal, modalcronica6A: false }) }
								else if (i === 16) { setShowModal({ ...ShowModal, modalcronica6B: false }) }
								else if (i === 17) { setShowModal({ ...ShowModal, modalcronica6C: false }) }
								else if (i === 18) { setShowModal({ ...ShowModal, modalcronica7A: false }) }
								else if (i === 19) { setShowModal({ ...ShowModal, modalcronica7B: false }) }
								else if (i === 20) { setShowModal({ ...ShowModal, modalcronica7C: false }) }
								else if (i === 21) { setShowModal({ ...ShowModal, modalcronica8A: false }) }
								else if (i === 22) { setShowModal({ ...ShowModal, modalcronica8B: false }) }
								else if (i === 23) { setShowModal({ ...ShowModal, modalcronica8C: false }) }
								else if (i === 24) { setShowModal({ ...ShowModal, modalcronica9A: false }) }
								else if (i === 25) { setShowModal({ ...ShowModal, modalcronica9B: false }) }
								else if (i === 26) { setShowModal({ ...ShowModal, modalcronica9C: false }) }
								else if (i === 27) { setShowModal({ ...ShowModal, modalcronica10A: false }) }
								else if (i === 28) { setShowModal({ ...ShowModal, modalcronica10B: false }) }
								else if (i === 29) { setShowModal({ ...ShowModal, modalcronica10C: false }) }
							}}
							className="Modal-Template-4 Modal-Template-4B">
							<Modal.Body>
								{
									<BackgroundImage fluid={BackgroundsImages[2].fluid}>
										{(Section2TitleChroniclesCategoriesModal && Section2TitleChroniclesCategoriesModal[i]) &&
											<div className="title">
												<Image fluid={Section2TitleChroniclesCategoriesModal[i].fluid} alt={Section2TitleChroniclesCategoriesModal[i].description ? Section2TitleChroniclesCategoriesModal[i].description : Section2TitleChroniclesCategoriesModal[i].title} />
												<CarouselCloseModal i={i} type={'cronica'} />
											</div>
										}
										{(Section2DownloadMaterialsModal && Section2DownloadMaterialsModal[i]) &&
											<div className="Modal-subcontainer Modal-subcontainer-1">
												<a rel="noreferrer" aria-label="redirect" href={`https:${Section2DownloadMaterialsModal[i].file.url}`} target="_blank" without="true" download>
													{modalDesingImages && modalDesingImages[3] &&
														<Image fluid={modalDesingImages[3].fluid} />
													}
												</a>
											</div>
										}
										<div className="Modal-subcontainer">
											<div>
												{(i === 0 || i === 3 || i === 6 || i === 9 || i === 12 || i === 15 || i === 18 || i === 21 || i === 24 || i === 27) && <Image fluid={modalDesingImages[0].fluid} />}
												{(i === 1 || i === 4 || i === 7 || i === 10 || i === 13 || i === 16 || i === 19 || i === 22 || i === 25 || i === 28) && <Image fluid={modalDesingImages[1].fluid} />}
												{(i === 2 || i === 5 || i === 8 || i === 11 || i === 14 || i === 17 || i === 20 || i === 23 || i === 26 || i === 29) && <Image fluid={modalDesingImages[2].fluid} />}
											</div>
											<div className="Modal-description">
												<div className="Modal-subdescription">
													{Section2Chronicles1ModalText[i] &&
														<Image fluid={Section2Chronicles1ModalText[i].fluid} alt={Section2Chronicles1ModalText[i].description ? Section2Chronicles1ModalText[i].description : Section2Chronicles1ModalText[i].title} />
													}
												</div>
												<div className="Modal-subdescription">
													{Section2ChroniclesModalText.Plantilla4 &&
														<React.Fragment>
															{Section2ChroniclesModalText.Plantilla4.Textos && Section2ChroniclesModalText.Plantilla4.Textos[i] &&
																<React.Fragment>
																	{<div dangerouslySetInnerHTML={createMarkup(Section2ChroniclesModalText.Plantilla4.Textos[i].texto)} />}
																</React.Fragment>
															}
														</React.Fragment>
													}
												</div>
											</div>
										</div>
									</BackgroundImage>
								}
							</Modal.Body>
						</Modal>
					))}
				</React.Fragment>
			}
		</Layout>
	)
}

export default Template4